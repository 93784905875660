import styles from './styles.module.scss';
import { ReactComponent as Brave } from './assets/brave.svg';
import { ReactComponent as Firefox } from './assets/firefox.svg';
import { ReactComponent as Chrome } from './assets/chrome.svg';

const browsersItem = [
  {
    title: 'Brave',
    icon: <Brave/>,
    href: 'https://brave.com/'
  },  {
    title: 'Firefox',
    icon:<Firefox/>,
    href: 'https://www.mozilla.org/en-US/firefox/new/'
  },  {
    title: 'Chrome',
    icon:<Chrome/>,
    href: 'https://www.google.com/intl/en/chrome/'
  }
]


export const ErrorPopup = () => {
  return (
    <div className={styles.error_popup}>
      <div className={styles.title}>OOOPS!</div>
      <div className={styles.paragraph}>
        You are using a browser that does not support the Metamask extension.
      </div>
      <div className={styles.browser_text}>
        Please use the following browsers:
      </div>
      <div className={styles.browsers_wrapper}>
        {browsersItem.map((item)=><div className={styles.browsers_item}>
          <a className={styles.browsers_content} href={item.href}>
            {item.icon}<div>{item.title}</div>
          </a>
          </div>)}
      </div>
    </div>
  );
};
