import styles from './styles.module.scss';

export const MetamaskPopup = ({ cancel }: any) => {
  return (
    <div className={styles.metamask_popup}>
      <div className={styles.icon} />
      <div className={styles.metamask}>Metamask</div>
      <div className={styles.paragraph}>
        For the Metamask plugin to work you need to install{' '}
        <span>a browser extension.</span>
      </div>
      <div className={styles.buttons}>
        <div className={styles.cancel} onClick={cancel}>
          cancel
        </div>
        <a href='https://metamask.io/download/' target='_blank'>
          <div className={styles.button_wrapper}>
            <div className={styles.cool_button}>
              <div className={styles.button} onClick={() => {}}>
                install extension
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
