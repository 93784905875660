import styles from './styles.module.scss';

export const WrongPopup = ({ close }: any) => {
  return (
    <div className={styles.wrong_popup}>
      <div className={styles.icon} />
      <div className={styles.under_icon} />
      <div className={styles.title}>Something went wrong</div>
      <div className={styles.paragraph}>
        Registration error, please try again or mail to{' '}
        <a href='mailto:support@ghostdrive.com'>support@ghostdrive.com</a>
      </div>
      <div className={styles.button_wrapper}>
        <div className={styles.cool_button}>
          <div className={styles.button} onClick={close}>
            ok
          </div>
        </div>
      </div>
    </div>
  );
};
