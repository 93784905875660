import styles from './styles.module.scss';
import whale from '../../assets/img/whale.png';
import { Modal } from 'components/modal';
import { useEffect, useState } from 'react';
import { MetamaskPopup } from 'components/metamaskPopup';
import { WrongPopup } from 'components/wrongPopup';
import { ErrorPopup } from 'components/errorContent';

const userAgents = ['Brave', 'Chrome', 'Firefox'];

export const NftPage = () => {
  const referral = new URLSearchParams(window.location.search).get('referral');
  const [seconds, setSeconds] = useState(0);
  const [data, setData] = useState({
    cap: '',
    currentPeriod: '',
    nextReleaseAt: 0,
    soldInPeriod: 0,
    supplyPerPeriod: 0,
    totalSupply: 0,
  });
  const [modal, setModal] = useState<null | string>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const error = queryParams.get('error');

  useEffect(() => {
    if (error && error === 'registration') {
      setModal('wrong');
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    fetch('https://api.app.ghostdrive.io/api/demo/nft-mint-details/56')
      .then((response) => response.json())
      .then((response) => setData(response));
  }, []);

  useEffect(() => {
    if (data.nextReleaseAt > 0) {
      setSeconds(data.nextReleaseAt - new Date().getTime() / 1000);
    }
  }, [data.nextReleaseAt]);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(setSeconds, 1000, seconds - 1);
    }
  }, [seconds]);

  const mintNow = () => {
    let arr: any = [];

    userAgents.map(
      (item) => (arr = [...arr, navigator.userAgent.indexOf(item)])
    );

    if (arr.find((item: any) => item !== -1)) {
      if (typeof window?.ethereum === 'undefined') {
        setModal('metamask');
        return;
      }
      if (typeof window?.ethereum !== 'undefined') {
        const { providers } = window?.ethereum;

        if (!providers && !window?.ethereum.isMetaMask) {
          setModal('metamask');
          return;
        }
        window.open(
          `https://app.ghostdrive.io/sign-up-metamask${
            referral ? `?referral=${referral}` : ''
          }`
        );
      }
    } else {
      setModal('wrong-browser');
    }
  };

  return (
    <>
      <div className={styles.background}>
        <div className={styles.nft_content}>
          <div className={styles.card_container}>
            <div className={styles.card}>
              <div className={styles.inner_card} />
            </div>
            <div className={styles.image_wrapper}>
              <img className={styles.img} src={whale} alt='Blue Whale' />
              <div className={styles.img_border} />
              <div className={styles.img_logo} />
              <div className={styles.img_close} />
              <div className={styles.img_name + ' ' + styles.img_name_1}>
                BABYWHALE
              </div>
              <div className={styles.img_name + ' ' + styles.img_name_2}>
                GHOSTDRIVE
              </div>
              <div className={styles.img_name + ' ' + styles.img_name_3}>
                NFTPASS
              </div>
              <div className={styles.img_name + ' ' + styles.img_name_4}>
                SUPPLY 21,000
              </div>
            </div>
            <div className={styles.content_wrapper}>
              <div className={styles.top}>
                <div className={styles.content_card} />
                <div className={styles.text}>
                  <div className={styles.title}></div>
                  <div className={styles.subtitle}>GHOSTDRIVE</div>
                  <div className={styles.items}>
                    <div>NFT PASS</div>
                    <div>EARLY ACCESS</div>
                    <div>BOOSTED REWARDS</div>
                    <div> MEMBER FOREVER</div>
                  </div>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.row}>
                  <div>0.02</div>
                  <div>BLUE WHALE</div>
                </div>
                <div className={styles.row}>
                  <div>BNB</div>
                  {data.supplyPerPeriod - data.soldInPeriod === 0 ? (
                    `${(seconds / 3600).toFixed()} h : ${(
                      (seconds % 3600) /
                      60
                    ).toFixed()} m: ${((seconds % 3600) % 60).toFixed()} s`
                  ) : (
                    <div>
                      Available {data.supplyPerPeriod - data.soldInPeriod || 0}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content_mobile}>
            <div className={styles.under_button}>{`// TOTAL ${
              data.totalSupply
            }/${data?.cap || 21000}`}</div>
            <div className={styles.button_wrapper}>
              <div className={styles.cool_button} onClick={mintNow}>
                <div className={styles.button}>MINT NOW</div>
              </div>
            </div>
            {/* <div className={styles.next_pass_mobile}>
              <div>BLUE GUARD \</div>
              <div>NEXT PASS</div>
            </div> */}
          </div>
          <div className={styles.description_container}>
            <div className={styles.title}>nft pass</div>
            <div className={styles.description_wrapper}>
              <div className={styles.description}>
                <div>
                  <div className={`${styles.value} ${styles.title}`}>
                    BABY WHALE
                  </div>
                </div>
                <span className={styles.line_mobile}></span>
                <div className={styles.info_block}>
                  <div className={styles.value}>Network</div>
                  <div className={styles.key}> BSC</div>
                </div>
                <span className={styles.line_mobile}></span>
                <div className={styles.info_block}>
                  <div className={styles.value}>COST</div>
                  <div className={styles.key}>
                    0.02 <span>BNB</span>
                  </div>
                </div>
              </div>
              <div className={styles.statistics}>
                <div>
                  <div className={styles.label}>10GB GHOSTDRIVE</div>
                  <div className={`${styles.progress} ${styles.first}`} />
                </div>
                <div>
                  <div className={styles.label}>200 BONUS</div>
                  <div className={`${styles.progress} ${styles.second}`} />
                </div>
                <div>
                  <div className={styles.label}>PRIME ACCESS</div>
                  <div className={`${styles.progress} ${styles.fourth}`} />
                </div>
              </div>
              <div className={styles.blue_member}>
                <div>
                  <div className={styles.instr}>INSTRUCTION</div>
                  <div className={styles.brw_title}>
                    Supported web browsers:
                  </div>
                  <div className={styles.brw}>Brave, Firefox, or Chrome</div>
                  <div className={styles.instruction_item}>
                    <div className={styles.badge}>
                      <span className={styles.badge_text}>1</span>
                    </div>
                    <div className={styles.instruction_list}>
                      Set up{' '}
                      <a
                        className={styles.instruction_link}
                        href='https://metamask.io'
                        target='_blank'
                      >
                        {' '}
                        Metamask
                      </a>
                    </div>
                  </div>
                  <div className={styles.instruction_item}>
                    <div className={styles.badge}>
                      <span className={styles.badge_text}>2</span>
                    </div>
                    <div className={styles.instruction_list}>
                      Incorporate the <b>BnB Chain Network</b>{' '}
                      <a
                        className={styles.instruction_link}
                        href='https://www.coingecko.com/learn/how-to-add-binance-smart-chain-bsc-to-metamask'
                        target='_blank'
                      >
                        Instruction
                      </a>
                    </div>
                  </div>
                  <div className={styles.instruction_item}>
                    <div className={styles.badge}>
                      <span className={styles.badge_text}>3</span>
                    </div>
                    <div className={styles.instruction_list}>
                      Add <b>0.03BNB</b> to your wallet, then select{' '}
                      <b>Mint NFT</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.button_wrapper}>
              <div className={styles.cool_button} onClick={mintNow}>
                <div className={styles.button}>MINT NOW</div>
              </div>
            </div>
            <div className={styles.under_button}>{`// TOTAL ${
              data.totalSupply
            }/${data?.cap || 21000}`}</div>
          </div>
        </div>
        {/* <div className={styles.next_pass}>
          <div>BLUE GUARD \</div>
          <div>NEXT PASS</div>
        </div> */}
      </div>

      {modal && (
        <Modal
          close={() => {
            setModal(null);
          }}
        >
          {modal === 'metamask' && (
            <MetamaskPopup
              close={() => {
                setModal(null);
              }}
            />
          )}
          {modal === 'wrong' && (
            <WrongPopup
              close={() => {
                setModal(null);
              }}
            />
          )}
          {modal === 'wrong-browser' && (
            <Modal
              close={() => {
                setModal(null);
              }}
            >
              <ErrorPopup />
            </Modal>
          )}
        </Modal>
      )}
    </>
  );
};
