import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = ({
  title,
  children,
}: {
  title: string;
  children: any;
}) => {
  const location = useLocation();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: title,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
  }, [location]);

  return <div>{children}</div>;
};

export default GoogleAnalytics;
